// import Amplify, { API, Auth, Cache } from "aws-amplify"

import Amplify from "@aws-amplify/core"
import API from "@aws-amplify/api"
import Auth from "@aws-amplify/auth"

import moment from "moment"

export function updateState(prop, val) {
  return dispatch => {
    dispatch({
      type: "SET_STATE",
      data: { prop, val },
    })
  }
}

export function updateVideoTags(sessionid, tags, mode) {
  let apiName = "Storylava"

  let path = "updatevideo"

  return dispatch => {
    dispatch({
      type: "LOADING_MINI_START",
      data: { sessionid },
    })
    Auth.currentAuthenticatedUser()
      .then(user => {
        //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
        console.log("get videocollection function---")
        console.log(user)
        let jwtToken = user.signInUserSession.idToken.jwtToken

        let options = {
          headers: {
            Authorization: jwtToken,
          },
          body: {
            sessionid: sessionid,
            updatemode: "update",
            tags: JSON.stringify(tags), //must be array
          },
        }

        if (mode === "add") {
          options.body.stage = "updatetags"
        } else if (mode === "delete") {
          options.body.stage = "deletetags"
        }

        //begin promise
        new Promise(resolve => {
          API.post(apiName, path, options)
            .then(response => {
              const data = response || {}

              console.log("((updateVideoTags - data))")
              console.log(data)

              return resolve(
                dispatch({
                  type: "VIDEO_TAGS_UPDATE",
                  data: data,
                })
              )
            })
            .catch(error => {
              console.log(error.response)
            })
        }) //end promise
      })
      .catch(error => {
        console.log(error)
      }) //end get Auth token
  }
}
