// import Amplify, { API, Auth, Cache } from "aws-amplify"

import Amplify from "@aws-amplify/core"
import API from "@aws-amplify/api"
import Auth from "@aws-amplify/auth"
import Cache from "@aws-amplify/cache"

import { groupBy } from "./groupBy"
// import lodashMap from "lodash.map"
import moment from "moment"

export function getVideoCollection(templateid) {
  let apiName = "Storylava"

  let path = "videocollection"

  function getMinTime(data) {
    return data.reduce((min, p) => (p.time < min ? p.time : min), data[0].time)
  }
  function getMaxTime(data) {
    return data.reduce((max, p) => (p.time > max ? p.time : max), data[0].time)
  }

  return dispatch => {
    dispatch({
      type: "LOADING_START",
    })
    Auth.currentAuthenticatedUser()
      .then(user => {
        //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
        console.log("actions - getVideoCollection - auth - user")
        console.log(user)
        let jwtToken = user.signInUserSession.idToken.jwtToken
        let options = {
          headers: {
            Authorization: jwtToken,
          },
          queryStringParameters: {
            t: templateid,
          },
        }

        //begin promise
        new Promise(resolve => {
          API.get(apiName, path, options)
            .then(response => {
              console.log("actions - getVideoCollection - then")
              console.log(response)
              const data = response || {}
              const usersResultContainer = {}
              const videoResultContainer = {}
              const reviewsResultContainer = {}

              console.log("actions - getVideoCollection - date")
              console.log(data)

              if (data.length === 0) {
                usersResultContainer[templateid] = {
                  email: [],
                  phone: [],
                }

                videoResultContainer[templateid] = {
                  labels: [],
                  data: [],
                  total: 0,
                  bounds: {
                    max: 0,
                    min: 0,
                  },
                }
                reviewsResultContainer[templateid] = []

                return resolve(
                  dispatch({
                    type: "VIDEOS_CREATED_GROUPED_REPLACE",
                    data: videoResultContainer,
                  }),
                  dispatch({
                    type: "VIDEOS_USERS_REPLACE",
                    data: usersResultContainer,
                  })
                  // dispatch({
                  //   type: "REVIEWS_REPLACE",
                  //   data: reviewsResultContainer,
                  // })
                )
              } //end data.length

              let results = data.map((item, i) => ({
                id: item.id,
                // sessionid: item.sessionid,
                // email: item.email,
                phone: item.phone,
                // emailconfirm: item.emailconfirm,
                // smsconfirm: item.smsconfirm,
                datecreated: item.datecreated,
                // tags: item.tags,
              }))

              //TODO: filter results by completion status?

              //GROUP RESULTS BY DATE FOR VIDEOS REPORTING PAGE
              // let groupedResults = lodashGroupBy(results, result =>
              //   moment
              //     .unix(result["datecreated"])
              //     // .format("YYYY-MM-DD")
              //     .startOf("day")
              // )
              let groupedResults = groupBy(
                results,
                result => moment.unix(result["datecreated"]).startOf("day")
                // .format("YYYY-MM-DD")
              )

              // console.log("--groupedResults--++")
              // console.log(groupedResults)

              let groupContainer = Object.keys(groupedResults).map(
                (key, index) => ({
                  time: parseInt(moment(key).format("x")),
                  value: groupedResults[key].length,
                })
              )

              // console.log("--groupContainer--++")
              // console.log(groupContainer)

              let max = getMaxTime(groupContainer)
              let min = getMinTime(groupContainer)

              // console.log("max")
              // console.log(max)
              // console.log("min")
              // console.log(min)

              //SEND MANUALLY PRODUCED TICKS
              let ticks = []
              let incrementValue = 86400000

              for (let i = min; i < parseInt(max + incrementValue); ) {
                ticks.push(i)
                // console.log("i")
                // console.log(i)
                i = i + incrementValue
              }

              // console.log("99ticks")
              // console.log(ticks)

              //PREPARE VIDEO REPORTING DATA
              videoResultContainer[templateid] = {
                labels: ticks,
                data: groupContainer,
                total: results.length,
                bounds: {
                  max: max,
                  min: min,
                },
              }
              // resultContainer[templateid] = groupContainer

              /// WORK ON USERS NOW

              // See: https://stackoverflow.com/questions/2218999/remove-duplicates-from-an-array-of-objects-in-javascript

              // const usersEmailList = results.filter(
              //   (item, index, self) =>
              //     item.email &&
              //     self.findIndex(t => t.email === item.email) === index
              // )
              const usersPhoneList = results.filter(
                (item, index, self) =>
                  item.phone &&
                  self.findIndex(t => t.phone === item.phone) === index
              )
              usersResultContainer[templateid] = {
                // email: usersEmailList,
                phone: usersPhoneList,
              }
              console.log("usersResultContainer")
              console.log(usersResultContainer)

              console.log("getVideoCollection - results")
              console.log(results)

              // /// WORK ON REVIEWS NOW
              // let reviewsList = results.map((item, i) => ({
              //   id: item.id,
              //   sessionid: item.sessionid,
              //   email: item.email,
              //   phone: item.phone,
              //   emailconfirm: item.emailconfirm,
              //   smsconfirm: item.smsconfirm,
              //   datecreated: item.datecreated,
              //   tags: item.tags,
              // }))

              // console.log("getVideoCollection - reviewsList")
              // console.log(reviewsList)

              // reviewsList = reviewsList.sort((a, b) =>
              //   a.datecreated > b.datecreated ? -1 : 1
              // )

              // reviewsResultContainer[templateid] = reviewsList

              // console.log("reviewsResultContainer")
              // console.log(reviewsResultContainer)

              return resolve(
                dispatch({
                  type: "VIDEOS_CREATED_GROUPED_REPLACE",
                  data: videoResultContainer,
                }),
                dispatch({
                  type: "VIDEOS_USERS_REPLACE",
                  data: usersResultContainer,
                })
                // dispatch({
                //   type: "REVIEWS_REPLACE",
                //   data: reviewsResultContainer,
                // })
              )
            })
            .catch(error => {
              console.log("actions - getVideoCollection - catch")
              console.log(error)
            })
        }) //end promise
      })
      .catch(error => {
        console.log(error)
      }) //end get Auth token
  }
}
