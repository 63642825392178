// import Amplify, { API, Auth, Cache } from "aws-amplify"

import Amplify from "@aws-amplify/core"
import API from "@aws-amplify/api"
import Auth from "@aws-amplify/auth"
import Cache from "@aws-amplify/cache"
import { navigate } from "@reach/router"

export function setCampaignAux(data) {
  return dispatch => {
    dispatch({
      type: "MODIFYCAMPAIGN_REPLACE",
      data,
    })
  }
}

export function modifyCampaign(mode, id, name, owner, editedid) {
  let apiName = "Storylavaweb"

  let path = "admin/campaign/modify"

  return dispatch => {
    dispatch({
      type: "LOADING_START",
    })
    let errorObj = { id: false, name: false }

    if (!id || !name || !mode) {
      if (mode === "add" || mode === "edit" || mode === "delete") {
        if (!id) errorObj.id = true
        if (!name) errorObj.name = true
        if (!mode) errorObj.mode = true

        dispatch({
          type: "MODIFYCAMPAIGN_ERROR",
          data: errorObj,
        })
        return
      } //add/edit/delete
    } else {
      dispatch({
        type: "MODIFYCAMPAIGN_ERROR",
        data: errorObj,
      })
    }

    Auth.currentAuthenticatedUser()
      .then(user => {
        //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
        console.log("actions - modifyCampaign - authuser - user")
        console.log(user)
        let jwtToken = user.signInUserSession.idToken.jwtToken

        let options = {
          headers: {
            Authorization: jwtToken,
          },
          body: {
            mode,
            id,
            name,
            owner,
            editedid,
          },
        }

        console.log("actions - modifyCampaign - options")
        console.log(options)
        //begin promise
        new Promise(resolve => {
          API.post(apiName, path, options)
            .then(response => {
              const data = response || {}

              console.log("actions - modifyCampaign - post - data")
              console.log(data)
              if (mode === "add" || mode === "edit" || mode === "delete") {
                dispatch({
                  type: "MODIFYCAMPAIGN_REPLACE",
                  data: data,
                })
              } else {
                console.log("actions - modifyCampaign - list")

                dispatch({
                  type: "MODIFYCAMPAIGN_LIST",
                  data: data.data,
                })
              }

              return resolve(
                dispatch({
                  type: "MODIFYCAMPAIGN_HISTORY",
                  data: data,
                })
              )
            })
            .catch(error => {
              console.log("actions - modifyCampaign - catch - error.response")
              console.log(error.response)
              return resolve(
                dispatch({
                  type: "MODIFYCAMPAIGN_HISTORY",
                  data: data,
                })
              )
            })
        }) //end promise
      })
      .catch(error => {
        console.log(error)
      }) //end get Auth token
  }
}

export function getCampaignEngage(id) {
  let apiName = "Storylavaweb"

  let path = "admin/campaign/engage"

  return dispatch => {
    dispatch({
      type: "LOADING_START",
    })
    Auth.currentAuthenticatedUser()
      .then(user => {
        //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
        console.log("get campaigns function---")
        console.log("[[[user]]]")
        console.log(user)
        let jwtToken = user.signInUserSession.idToken.jwtToken
        let options = {
          headers: {
            Authorization: jwtToken,
          },
          queryStringParameters: {
            id: id,
          },
        }

        //begin promise
        new Promise(resolve => {
          API.get(apiName, path, options)
            .then(response => {
              const data = response || {}

              console.log("((data - campaign engage))")
              console.log(data)

              return resolve(
                dispatch({
                  type: "MODIFYCAMPAIGN_ENGAGE_REPLACE",
                  data,
                })
              )
            })
            .catch(error => {
              console.log(error.response)
            })
        }) //end promise
      })
      .catch(error => {
        console.log(error)
      }) //end get Auth token
  }
}
