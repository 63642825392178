import React from "react"
import { connect } from "react-redux"

import { MdMail, MdError, MdUndo } from "react-icons/md"
import { GoCheck } from "react-icons/go"

import { downloadCSV } from "../../actions/setCollector"

// const mapDispatchToProps = dispatch => ({
//   downloadCSV: contactsArray => dispatch(downloadCSV(contactsArray)),
// })

const mapStateToProps = ({ contactcollector }) => {
  return {
    contactcollector,
  }
}

class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const { contactcollector, marker } = this.props
    console.log("((((contactcollector ----- 1))))")
    console.log(contactcollector)

    return (
      <>
        {this.props.message === "signalsend" ? (
          <div class="d-flex flex-row mb-1">
            <div class="status-icon d-flex align-items-start justify-content-start">
              <MdMail class="grayblue-1" />
            </div>

            <div class="msg grayblue-1 ml-2">
              <div class="font-weight-bold">
                Sent{this.props.dryRun ? " :: DRY RUN" : null}{" "}
                {this.props.count ? ` :: COUNT: ${this.props.count}` : null}
              </div>
              <div>
                {this.props.recipient
                  ? this.props.recipient.map((user) => (
                      <div>
                        {user.email} {user.bcc ? ` :: BCC//${user.bcc}` : ""}
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>
        ) : null}
        {this.props.message === "success" ? (
          <div class="d-flex flex-row mb-1">
            <div class="status-icon d-flex align-items-start justify-content-start">
              <MdMail class="grayblue-1" />
            </div>

            <div class="msg grayblue-1 ml-2">
              <div class="font-weight-bold">
                Sent{this.props.dryRun ? " :: DRY RUN" : null}
              </div>
              <div>
                {this.props.recipient
                  ? this.props.recipient.map((user) => (
                      <div>
                        {user.email}
                        {user.bcc ? ` :: BCC//${user.bcc}` : ""}
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>
        ) : null}
        {this.props.message === "unsub" ? (
          <div class="d-flex flex-row mb-1">
            <div class="status-icon d-flex align-items-start justify-content-start">
              <MdUndo class="dkorange-1" />
            </div>
            <div class="msg dkorange-1 ml-2">
              <div class="font-weight-bold">
                {this.props.message.toUpperCase()}
                {this.props.dryRun ? " :: DRY RUN" : null}
              </div>
              <div>
                {this.props.recipient
                  ? this.props.recipient.map((user) => <div>{user.email}</div>)
                  : null}
              </div>
            </div>
          </div>
        ) : null}
        {this.props.message === "notfound" ? (
          <div class="d-flex flex-row mb-1">
            <div class="status-icon d-flex align-items-start justify-content-start">
              <MdUndo class="dkorange-1" />
            </div>
            <div class="msg dkorange-1 ml-2">
              <div class="font-weight-bold">
                {this.props.message.toUpperCase()}
                {this.props.dryRun ? " :: DRY RUN" : null}
              </div>
              <div>
                {this.props.attempt
                  ? this.props.attempt.map((user) => <div>{user.email}</div>)
                  : null}
              </div>
            </div>
          </div>
        ) : null}
        {this.props.message === "error" ||
        this.props.message === "emailerror" ? (
          <div class="d-flex flex-row mb-1">
            <div class="status-icon d-flex align-items-start justify-content-start">
              <MdError class="red-1" />
            </div>
            <div class="msg red-1 ml-2">
              <div class="font-weight-bold">
                Error{this.props.dryRun ? " :: DRY RUN" : null}
              </div>

              <div>
                {this.props.recipient
                  ? this.props.recipient.map((user) => <div>{user.email}</div>)
                  : null}
              </div>
              {/* <div class="font-weight-light">Error message</div> */}
            </div>
          </div>
        ) : null}
        {this.props.message === "collectorsuccess" ? (
          <div class="d-flex flex-row mb-1">
            <div class="status-icon d-flex align-items-start justify-content-start">
              <GoCheck class="dkgreen-1" />
            </div>
            <div class="msg dkgreen-1 ml-2">
              <div class="font-weight-bold">
                Collector {marker + 1} completed
              </div>
              <a
                href="#"
                class="msg"
                onClick={() => downloadCSV(contactcollector[marker].contacts)}
              >
                Download again
              </a>
            </div>
          </div>
        ) : null}
        {this.props.message === "acerror" ||
        this.props.message === "acsuccess" ? (
          <div class="d-flex flex-row mb-1">
            <div class="status-icon d-flex align-items-start justify-content-start">
              {this.props.message === "acerror" ? (
                <MdError class="red-1" />
              ) : null}
              {this.props.message === "acsuccess" ? (
                <GoCheck class="dkgreen-1" />
              ) : null}
            </div>
            {this.props.message === "acerror" ? (
              <div class="msg ml-2 red-1">
                <div class="font-weight-bold">Error</div>
                <div class="font-weight-light">
                  {this.props.mode === "precheck"
                    ? `Campaign code ${this.props.data.id.replace(
                        "ac-",
                        ""
                      )} already in use`
                    : null}
                  {this.props.mode === "add" || this.props.mode === "edit"
                    ? `Couldn't ${this.props.mode} the campaign`
                    : null}
                </div>
              </div>
            ) : null}
            {this.props.message === "acsuccess" ? (
              <div class="msg ml-2 dkgreen-1">
                <div class="font-weight-bold">
                  {this.props.mode === "add"
                    ? `Created campaign: ${this.props.data.Attributes.id.replace(
                        "ac-",
                        ""
                      )}`
                    : null}
                  {this.props.mode === "edit"
                    ? `Updated campaign: ${this.props.data.Attributes.id.replace(
                        "ac-",
                        ""
                      )}`
                    : null}
                  {this.props.mode === "delete"
                    ? `Deleted campaign: ${this.props.data.id.replace(
                        "ac-",
                        ""
                      )}`
                    : null}
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
      </>
    )
  }
}
export default connect(mapStateToProps, null)(Snippet)

// export default Snippet
