// import 'babel-polyfill';
// import 'isomorphic-fetch';
import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { connect } from "react-redux"
import Amplify from "@aws-amplify/core"
import API from "@aws-amplify/api"
import Auth from "@aws-amplify/auth"
import Cache from "@aws-amplify/cache"

import { amplifyconfig } from "../../utils/config"

Amplify.configure(amplifyconfig)

import {
  setEmailHtml,
  setEmailRecipients,
  sendMail,
  setMergeAux,
} from "../../actions/setMailMerge"

import { setUserData } from "../../actions/setUserData"

import { CSVReader } from "react-papaparse"
const buttonRef = React.createRef()
const buttonRef2 = React.createRef()

import parse from "url-parse"

import Loading from "../loading"
import EmailStatus from "./emailStatus"

import { MdRemoveCircle, MdFileUpload, MdSend } from "react-icons/md"
import { IoMdArrowRoundBack, IoMdArrowRoundForward } from "react-icons/io"

const mapStateToProps = ({
  mailmerge,
  mailmergeusers,
  mailmergeaux,
  userdata,
  sendmail,
  sendmailerror,
  isLoading,
}) => {
  return {
    mailmerge,
    mailmergeusers,
    mailmergeaux,
    userdata,
    sendmail,
    sendmailerror,
    isLoading,
  }
}

const mapDispatchToProps = (dispatch) => ({
  setEmailHtml: (emailHtml) => dispatch(setEmailHtml(emailHtml)),
  setEmailRecipients: (recipients) => dispatch(setEmailRecipients(recipients)),
  sendMail: (
    mode,
    recipient,
    subject,
    bcc,
    content,
    dryrun,
    gettingstarted,
    overrideunsub
  ) =>
    dispatch(
      sendMail(
        mode,
        recipient,
        subject,
        bcc,
        content,
        dryrun,
        gettingstarted,
        overrideunsub
      )
    ),
  setMergeAux: (data) => dispatch(setMergeAux(data)),
  setUserData: (user) => dispatch(setUserData(user)),
})

// const Snippet = props => (
class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)

    this.state = {
      // emailTextLoaded: false,
      // recipientsLoaded: false,
      // dataMarker: 1,
      // dryRun: true,
      // emailBcc: "6297259@bcc.hubspot.com",
    }
  }
  componentDidMount() {
    console.log("<<<<<<<<<this.props.userdata>>>>>>>>>>>>>")
    console.log(this.props.userdata)
    if (!this.props.userdata) {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          console.log("<<<<user>>>>")
          console.log(user)
          this.props.setUserData(user)
        })
        .catch((error) => {
          console.log("auth - error")
          console.log(error)
        }) //end get Auth token
    }
  }

  handleUpdate = (event) => {
    console.log("handleUpdate - event")
    console.log(event.target)
    console.log(event.target.name)
    console.log(event.target.type)
    console.log(event.target.checked)
    if (event.target.type === "checkbox") {
      this.props.setMergeAux({
        [event.target.name]: event.target.checked,
      })
    } else {
      this.props.setMergeAux({
        [event.target.name]: event.target.value,
      })
    }
  }

  handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    console.log("handleOpenDialog - e")
    console.log(e)
    if (buttonRef.current) {
      buttonRef.current.open(e)
    }
    if (buttonRef2.current) {
      buttonRef2.current.open(e)
    }
  }

  handleOnFileLoad = (data) => {
    console.log("*** handleOnFileLoad")
    console.log(buttonRef)
    this.props.setMergeAux({
      recipientsLoaded: true,
    })
    this.props.setMergeAux({
      recipientsFileName: buttonRef.current.state.file.name,
    })
    this.props.setEmailRecipients(data)
    console.log("---------------------------")
    console.log(data)
    console.log("---------------------------")
  }

  handleOnError = (err, file, inputElem, reason) => {
    console.log(err)
  }

  handleOnRemoveFile = (data) => {
    console.log("---------------------------")
    console.log(data)
    console.log("---------------------------")
  }

  handleRemoveFile = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e)
    }
    this.props.setEmailRecipients()

    this.props.setMergeAux({
      recipientsLoaded: false,
    })
  }
  handleRemoveFileEmail = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef2.current) {
      buttonRef2.current.removeFile(e)
    }
    this.props.setMergeAux({
      emailTextLoaded: false,
    })
    this.props.setMergeAux({
      emailTextFileName: "",
    })
    this.props.setEmailHtml("")
  }

  handleChange(event) {
    // User cancelled
    if (!event.target.files[0]) {
      return
    }
    let that = this
    console.log("event.target.files[0].name")
    console.log(event.target.files[0].name)
    this.props.setMergeAux({
      emailTextFileName: event.target.files[0].name,
    })
    const reader = new FileReader()
    reader.onload = function (e) {
      // e.target.result should contain the text
      console.log("e.target.result")
      console.log(e.target.result)
      that.props.setEmailHtml(e.target.result)
      that.props.setMergeAux({
        emailTextLoaded: true,
      })
    }
    reader.readAsText(event.target.files[0])
  }

  replaceSoloString(fieldname, i) {
    const { mailmerge, mailmergeusers, mailmergeaux, isLoading } = this.props
    // let k
    const fieldIndex =
      mailmergeusers && mailmergeusers[0] && mailmergeusers[0].data
        ? mailmergeusers[0].data.indexOf(fieldname)
        : null
    console.log("fieldIndex")
    console.log(fieldIndex)
    // console.log("mailmergeusers[i].data[fieldIndex]")
    // console.log(mailmergeusers[i].data[fieldIndex])
    let mergedField = mailmergeusers[i].data[fieldIndex]
    return mergedField
      ? mergedField
      : `[ ${mailmergeusers[0].data[fieldIndex]} ]`

    // // let mergeArray = []
    // let mergeContainer = []

    // // let mergeLength = mailmergeusers.length
    // if (mailmergeusers && mailmergeusers[0] && mailmergeusers[0].data) {
    //   for (k = 1; k < mailmergeusers.length; k++) {
    //     let mergeArray = mailmergeusers[0].data.map((item, j) => ({
    //       [item]: mailmergeusers[2].data[j],
    //     }))
    //     mergeContainer.push(mergeArray)
    //   }

    //   console.log("mergeContainer")
    //   console.log(mergeContainer)
    // }
    // return mergeContainer
  }
  replaceBulk(str, findArray, replaceArray) {
    var i,
      regex = [],
      map = {}
    for (i = 0; i < findArray.length; i++) {
      regex.push(findArray[i].replace(/([-[\]{}()*+?.\\^$|#,])/g, "\\$1"))
      map[findArray[i]] = replaceArray[i]
    }
    regex = regex.join("|")
    str = str.replace(new RegExp(regex, "g"), function (matched) {
      return map[matched]
    })
    return str
  }
  replaceHtmlStrings(emailHtml, i) {
    const { mailmerge, mailmergeusers, isLoading } = this.props

    console.log("mailmergeusers - 1")
    console.log(mailmergeusers)
    // let mailmergeData = [...mailmergeusers]
    // if (mailmergeData && mailmergeData[0] && mailmergeData[0].data) {
    //   let dataLength = mailmergeData[0].data.length
    //   //check if already has been added
    //   if (mailmergeData[0].data[dataLength - 1] !== "campaign_code") {
    //     //add 'campaign_code' to end of each dataset
    //     mailmergeData[0].data.push("campaign_code")

    //     //add campaign_code value to end of each dataset
    //     mailmergeData.forEach(item =>
    //       item.data.push(this.state.emailCampaignId)
    //     )
    //   } //end check if already has been added
    // }

    // let replacementStrings = this.getStringsToReplace()

    // let testArray = replacementStrings[0]

    //Get key name. https://stackoverflow.com/questions/55583869/javascript-get-key-name-from-array-of-objects
    // let keyMap =
    //   replacementStrings && replacementStrings[0]
    //     ? replacementStrings[0].map(item => `{{${Object.keys(item)[0]}}}`)
    //     : []
    let keyMap =
      mailmergeusers && mailmergeusers[0] && mailmergeusers[0].data
        ? mailmergeusers[0].data.map((item) => `{{${item}}}`)
        : []
    let valueMap =
      mailmergeusers && mailmergeusers[i] && mailmergeusers[i].data
        ? mailmergeusers[i].data.map((item) => item)
        : []
    let keyMap2 =
      mailmergeusers && mailmergeusers[0] && mailmergeusers[0].data
        ? mailmergeusers[0].data.map((item) => `%7B%7B${item}%7D%7D`)
        : []
    let valueMap2 =
      mailmergeusers && mailmergeusers[i] && mailmergeusers[i].data
        ? mailmergeusers[i].data.map((item) => item)
        : []
    // console.log("[[[[testArray]]]]")
    // console.log(testArray)
    console.log("[[[[keyMap]]]]")
    console.log(keyMap)
    console.log("[[[[valueMap]]]]")
    console.log(valueMap)
    // console.log("[[[[emailHtml]]]]")
    // console.log(emailHtml)
    let emailReplaced = this.replaceBulk(emailHtml, keyMap, valueMap)
    emailReplaced = this.replaceBulk(emailReplaced, keyMap2, valueMap2)
    return emailReplaced
  }

  incrementDataMarker(direction) {
    if (direction === "up") {
      this.props.setMergeAux({
        dataMarker: this.props.mailmergeaux.dataMarker + 1,
      })
    } else {
      this.props.setMergeAux({
        dataMarker: this.props.mailmergeaux.dataMarker - 1,
      })
    }
  }

  render() {
    const {
      mailmerge,
      mailmergeusers,
      mailmergeaux,
      userdata,
      sendmail,
      sendmailerror,
      isLoading,
    } = this.props
    console.log("((((mailmergeaux))))")
    console.log(mailmergeaux)

    const emailSender =
      !mailmergeaux.dryRun && mailmergeusers
        ? this.replaceSoloString("email", mailmergeaux.dataMarker)
        : userdata && userdata.attributes && userdata.attributes.email
        ? userdata.attributes.email
        : ""

    return (
      <>
        <div class="col-lg-9">
          <div class="reporting-container merge-container">
            <div class="row pt-1 mb-4">
              <div class="merge-subtitle font-weight-bold">Signal send*</div>
              <br />

              <button
                class="btn btn-yellow"
                onClick={() => {
                  console.log("sending mail** ")
                  this.props.sendMail(
                    "signalsend",
                    "",
                    mailmergeaux.emailSubject,
                    mailmergeaux.emailBcc,
                    this.replaceHtmlStrings(mailmerge, mailmergeaux.dataMarker),
                    mailmergeaux.dryRun,
                    mailmergeaux.gettingStarted,
                    mailmergeaux.overrideUnsub
                  )
                }}
              >
                <MdSend size={25} />
              </button>
            </div>
            <div class="row bg-gray-7 pt-1 rounded-corners-top">
              <div class="col-md-6 form-group">
                <div>
                  <div class="merge-subtitle font-weight-bold">Email text</div>
                  <div class="d-flex flex-row merge-subcontainer">
                    <div
                      class={`btn btn-link btn-sm merge-upload ${
                        !mailmergeaux.emailTextLoaded ? "d-block" : "d-none"
                      }`}
                    >
                      <input
                        ref={buttonRef}
                        type="file"
                        id="email-file-upload"
                        onChange={this.handleChange}
                      />
                      <label for="email-file-upload">
                        <MdFileUpload class="blue-1" size={20} />
                      </label>
                    </div>
                    <button
                      class={`btn btn-link btn-sm merge-remove ${
                        mailmergeaux.emailTextLoaded ? "d-block" : "d-none"
                      }`}
                      onClick={this.handleRemoveFileEmail}
                    >
                      <MdRemoveCircle size={20} />
                    </button>
                    <div class="merge-filename">
                      {mailmergeaux.emailTextFileName}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 form-group">
                <CSVReader
                  ref={buttonRef}
                  onFileLoad={this.handleOnFileLoad}
                  onError={this.handleOnError}
                  noClick
                  noDrag
                  onRemoveFile={this.handleOnRemoveFile}
                  progressBarColor="#079dd9"
                >
                  {({ file }) => (
                    <div class="mb-2">
                      <div class="merge-subtitle font-weight-bold">
                        Recipients
                      </div>
                      <div class="d-flex flex-row merge-subcontainer">
                        <button
                          type="button"
                          onClick={this.handleOpenDialog}
                          class={`btn btn-link btn-sm merge-upload ${
                            !mailmergeaux.recipientsLoaded
                              ? "d-block"
                              : "d-none"
                          }`}
                        >
                          <MdFileUpload class="blue-1" size={20} />
                        </button>
                        <button
                          class={`btn btn-link btn-sm merge-remove ${
                            mailmergeaux.recipientsLoaded ? "d-block" : "d-none"
                          }`}
                          onClick={this.handleRemoveFile}
                        >
                          <MdRemoveCircle size={20} />
                        </button>
                        <div class="merge-filename">
                          {mailmergeaux.recipientsFileName}
                        </div>
                      </div>
                    </div>
                  )}
                </CSVReader>
              </div>
            </div>
            {/* {isLoading ? <Loading /> : null} */}
            {mailmerge && mailmergeusers ? (
              <>
                <div class="row bg-gray-7 merge-control-row pb-2">
                  <div class="col-md-6">
                    <div class="merge-subtitle font-weight-bold">Subject</div>
                    <textarea
                      type="email"
                      class={`form-control form-control-sm rounded-corners ${
                        sendmailerror && sendmailerror.subject
                          ? "border-2-red-1"
                          : ""
                      }`}
                      id="emailSubject"
                      placeholder="Subject"
                      rows="1"
                      value={mailmergeaux.emailSubject}
                      name="emailSubject"
                      onChange={this.handleUpdate}
                    />
                  </div>
                  <div class="col-md-6">
                    <div class="merge-subtitle font-weight-bold">BCC</div>
                    <textarea
                      type="email"
                      class="form-control form-control-sm rounded-corners"
                      id="exampleFormControlInput1"
                      placeholder="BCC"
                      rows="1"
                      value={mailmergeaux.emailBcc}
                      id="emailBcc"
                      name="emailBcc"
                      onChange={this.handleUpdate}
                    />
                  </div>
                </div>
                <div class="row bg-gray-7 merge-control-row pb-2 mb-3 rounded-corners-bottom">
                  <div class="col-md-6 d-flex flex-row">
                    {/* <div class="merge-subtitle font-weight-bold">
                      Campaign ID
                    </div>
                    <input
                      type="text"
                      class={`form-control form-control-sm rounded-corners ${
                        sendmailerror && sendmailerror.campaignid
                          ? "border-2-red-1"
                          : ""
                      }`}
                      placeholder="Campaign ID"
                      id="emailCampaignId"
                      name="emailCampaignId"
                      onChange={this.handleUpdate}
                      value={this.state.emailCampaignId}
                    /> */}
                    <div class="mr-2">
                      <label
                        class="merge-subtitle font-weight-bold text-center p-0 m-0"
                        for="dryRun"
                      >
                        Dry run
                      </label>
                      <div class="d-flex align-items-center justify-content-center">
                        <input
                          class="mt-1"
                          type="checkbox"
                          value=""
                          id="dryRun"
                          name="dryRun"
                          onChange={this.handleUpdate}
                          checked={mailmergeaux.dryRun}
                        />
                      </div>
                    </div>
                    <div class="mr-2">
                      <label
                        class="merge-subtitle font-weight-bold text-center p-0 m-0"
                        for="gettingStarted"
                      >
                        Getting started
                      </label>
                      <div class="d-flex align-items-center justify-content-center">
                        <input
                          class="mt-1"
                          type="checkbox"
                          value=""
                          id="gettingStarted"
                          name="gettingStarted"
                          onChange={this.handleUpdate}
                          checked={mailmergeaux.gettingStarted}
                        />
                      </div>
                    </div>
                    <div class="mr-2">
                      <label
                        class="merge-subtitle font-weight-bold text-center p-0 m-0"
                        for="overrideUnsub"
                      >
                        Override unsub
                      </label>
                      <div class="d-flex align-items-center justify-content-center">
                        <input
                          class="mt-1"
                          type="checkbox"
                          value=""
                          id="overrideUnsub"
                          name="overrideUnsub"
                          onChange={this.handleUpdate}
                          checked={mailmergeaux.overrideUnsub}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="merge-subtitle">&nbsp;</div>
                    {mailmerge && mailmergeusers ? (
                      <div class="d-flex flex-row">
                        <div class="font-weight-light text-center gray-2 p-2 mx-2">
                          {mailmergeaux.dataMarker}/{mailmergeusers.length - 1}
                        </div>
                        {mailmergeaux.dataMarker > 1 ? (
                          <button
                            class="btn btn-primary mx-1"
                            onClick={() => this.incrementDataMarker("down")}
                          >
                            <IoMdArrowRoundBack size={25} />
                          </button>
                        ) : (
                          <button class="btn btn-disabled mx-1">
                            <IoMdArrowRoundBack size={25} />
                          </button>
                        )}
                        {mailmergeaux.dataMarker < mailmergeusers.length - 1 ? (
                          <button
                            class="btn btn-primary mx-1"
                            onClick={() => this.incrementDataMarker("up")}
                          >
                            <IoMdArrowRoundForward size={25} />
                          </button>
                        ) : (
                          <button class="btn btn-disabled mx-1">
                            <IoMdArrowRoundForward size={25} />
                          </button>
                        )}

                        <div class="d-flex flex-column mx-1 ml-auto px-3">
                          <button
                            class="btn btn-success "
                            onClick={() =>
                              this.props.sendMail(
                                "solo",
                                emailSender,
                                mailmergeaux.emailSubject,
                                mailmergeaux.emailBcc,
                                this.replaceHtmlStrings(
                                  mailmerge,
                                  mailmergeaux.dataMarker
                                ),
                                mailmergeaux.dryRun,
                                mailmergeaux.gettingStarted,
                                mailmergeaux.overrideUnsub
                              )
                            }
                          >
                            <MdSend size={25} />
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12 d-flex flex-row">
                    <div
                      class={`merge-subtitle mx-2 d-flex flex-row ${
                        sendmailerror && sendmailerror.recipient
                          ? "border-1-red-1 rounded-corners px-2"
                          : ""
                      }`}
                    >
                      {mailmergeaux.dryRun ? (
                        <span class="font-weight-light red-1">
                          {userdata &&
                          userdata.attributes &&
                          userdata.attributes.email
                            ? userdata.attributes.email
                            : "ERROR"}
                          &nbsp;
                          {/* {this.replaceSoloString("email", mailmergeaux.dataMarker)} */}
                        </span>
                      ) : null}

                      <span
                        class={
                          mailmergeaux.dryRun
                            ? "font-weight-light strikethrough"
                            : "font-weight-light"
                        }
                      >
                        {this.replaceSoloString(
                          "email",
                          mailmergeaux.dataMarker
                        )}
                      </span>
                    </div>
                    <div class="merge-subtitle font-weight-light mx-2">/</div>
                    <div class="merge-subtitle mx-2">
                      <span class="font-weight-light">
                        {this.replaceSoloString(
                          "first_name",
                          mailmergeaux.dataMarker
                        )}{" "}
                        {this.replaceSoloString(
                          "last_name",
                          mailmergeaux.dataMarker
                        )}
                      </span>
                    </div>
                    <div class="merge-subtitle font-weight-light mx-2">/</div>
                    <div class="merge-subtitle mx-2">
                      <span class="font-weight-light">
                        {this.replaceSoloString(
                          "job_title",
                          mailmergeaux.dataMarker
                        )}
                      </span>
                    </div>
                    <div class="merge-subtitle font-weight-light mx-2">/</div>
                    <div class="merge-subtitle mx-2">
                      <span class="font-weight-light">
                        {this.replaceSoloString(
                          "company",
                          mailmergeaux.dataMarker
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="border-1-gray-7 p-3 rounded-corners">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.replaceHtmlStrings(
                            mailmerge,
                            mailmergeaux.dataMarker
                          ),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
        <div class="col-lg-3" style={{ top: -5 }}>
          <div class="border-1-gray-6 rounded-corners p-3 status-bar">
            {isLoading ? (
              <div class="pb-2">
                <Loading class="small" />
              </div>
            ) : null}
            {/* {JSON.stringify(sendmail)} */}
            {sendmail
              ? sendmail.map((item, i) => (
                  <div key={i}>
                    <EmailStatus
                      dryRun={
                        (userdata &&
                          userdata.attributes &&
                          userdata.attributes.email === item.recipient) ||
                        item.dryrun
                          ? true
                          : false
                      }
                      message={item.message}
                      recipient={item.recipient}
                      attempt={item.attempt}
                      count={item.count}
                    />
                  </div>
                ))
              : null}
          </div>
        </div>
      </>
    )
  }
}

// Snippet.propTypes = {
//   // result: PropTypes.array,
//   // increment: PropTypes.func.isRequired,
//   clear: PropTypes.func.isRequired,
// }

// export default Snippet
export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
