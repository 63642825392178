// import 'babel-polyfill';
// import 'isomorphic-fetch';
import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { connect } from "react-redux"
import Amplify from "@aws-amplify/core"
import API from "@aws-amplify/api"
import Auth from "@aws-amplify/auth"
import Cache from "@aws-amplify/cache"

import { amplifyconfig } from "../../utils/config"

Amplify.configure(amplifyconfig)

import {
  modifyCampaign,
  getCampaignEngage,
  setCampaignAux,
} from "../../actions/modifyCampaign"
import { setUserData } from "../../actions/setUserData"

import parse from "url-parse"

import Loading from "../loading"
import EmailStatus from "../appActivation/emailStatus"

import { MdRefresh, MdContentCopy } from "react-icons/md"
import { FaTrashAlt } from "react-icons/fa"

const moment = require("moment")
const copyToClipboard = require("clipboard-copy")

const mapStateToProps = ({
  userdata,
  modcampaign,
  modcampaignlist,
  modcampaignhistory,
  modcampaignerror,
  modcampaignengage,
  isLoading,
}) => {
  return {
    userdata,
    modcampaign,
    modcampaignlist,
    modcampaignhistory,
    modcampaignerror,
    modcampaignengage,
    isLoading,
  }
}

const mapDispatchToProps = dispatch => ({
  setUserData: user => dispatch(setUserData(user)),
  modifyCampaign: (mode, id, name, owner) =>
    dispatch(modifyCampaign(mode, id, name, owner)),
  getCampaignEngage: id => dispatch(getCampaignEngage(id)),
  setCampaignAux: data => dispatch(setCampaignAux(data)),
})

// const Snippet = props => (
class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    console.log("<<<<<<<<<this.props.userdata>>>>>>>>>>>>>")
    console.log(this.props.userdata)
    if (!this.props.userdata) {
      Auth.currentAuthenticatedUser()
        .then(user => {
          console.log("<<<<user>>>>")
          console.log(user)
          this.props.setUserData(user)
        })
        .catch(error => {
          console.log("auth - error")
          console.log(error)
        }) //end get Auth token
    }

    console.log(
      "<<<<<<<<<this.props.modcampaign - componentDidMount >>>>>>>>>>>>>"
    )

    this.props.modifyCampaign("list", null, null, null)
  }

  handleUpdate = event => {
    console.log("handleUpdate - event")
    console.log(event)
    console.log(event.target.name)
    console.log(event.target.value)
    const { modcampaignlist } = this.props

    console.log("modcampaignlist")
    console.log(modcampaignlist)
    if (event.target.type === "checkbox") {
      this.props.setCampaignAux({
        [event.target.name]: event.target.checked,
      })
    } else if (
      event.target.name === "campaignMode" &&
      event.target.value === "add"
    ) {
      this.props.setCampaignAux({
        campaignId: "",
        campaignName: "",
        campaignMode: "add",
      })
    } else if (
      event.target.name === "campaignMode" &&
      event.target.value !== "add" &&
      event.target.value !== "delete"
    ) {
      //is edit
      const currentCampaign = modcampaignlist.find(
        ({ id }) => id === `ac-${event.target.value}`
      )

      this.props.setCampaignAux({
        campaignId: currentCampaign.id.replace("ac-", ""),
        campaignName: currentCampaign.name,
        campaignMode: currentCampaign.id.replace("ac-", ""),
      })
    } else if (event.target.name === "campaignId") {
      let stringEdit = event.target.value.replace(/[^\w\s]/gi, "")
      stringEdit = stringEdit.toUpperCase()
      this.props.setCampaignAux({
        [event.target.name]: stringEdit,
      })
    } else {
      console.log("---else---")
      this.props.setCampaignAux({
        [event.target.name]: event.target.value,
      })
    }
  }
  handleSearch = event => {
    console.log("handleUpdate - event")
    console.log(event)
    console.log(event.target.name)
    console.log(event.target.value)
    this.props.setCampaignAux({
      [event.target.name]: event.target.value,
    })
    this.props.getCampaignEngage(event.target.value)
  }

  handleLinkRewrite(url, crmid, campaignid) {
    console.log("handleLinkRewrite - url")
    console.log(url)
    const baseDomain = "https://storylava.com"
    const parsed = parse(url, true)

    console.log("handleLinkRewrite - parsed")
    console.log(parsed)

    const protocol = parsed.protocol
    const domain = parsed.host
    let pathname = parsed.pathname
    let subdomain
    console.log("counter******")
    console.log(domain.match(/\./g))
    if (!domain.includes("storylava.com")) {
      this.props.setCampaignAux({
        linkRewrite: "Needs to be the Storylava domain",
      })
      return
    } else if (domain.match(/\./g).length >= 3) {
      this.props.setCampaignAux({
        linkRewrite: "Too many subdomains",
      })
      return
    } else if (domain.match(/\./g).length > 1 && domain.split(".")[1]) {
      subdomain = domain.split(".")[0]
    }

    const lastChar = pathname.substr(-1) // Selects the last character
    if (lastChar !== "/") {
      // If the last character is not a slash
      pathname = `${pathname}/`
    }

    let linkUrl = `${baseDomain}/r/?p=${pathname}&c=${campaignid}`
    if (subdomain) {
      linkUrl = `${linkUrl}&d=${subdomain}`
    }
    if (crmid) {
      linkUrl = `${linkUrl}&sb=${crmid}&tc=l`
    }

    this.props.setCampaignAux({
      linkRewrite: linkUrl,
    })
  }

  copyText(text) {
    let that = this
    copyToClipboard(text)
    this.setState({ copied: true }, () =>
      setTimeout(() => {
        that.setState({ copied: false })
      }, 1500)
    )
  }

  render() {
    const {
      userdata,
      modcampaign,
      modcampaignlist,
      modcampaignhistory,
      modcampaignerror,
      modcampaignengage,
      isLoading,
    } = this.props

    console.log("[[modcampaignengage]]")
    console.log(modcampaignengage)
    return (
      <>
        <div class="col-lg-9">
          <div class="reporting-container">
            <div class="row bg-gray-7 pt-3 rounded-corners">
              <div class="col-md-8 form-group">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a
                      class={`nav-link ${
                        modcampaign.tabState === "search" ? "active" : ""
                      }`}
                      data-toggle="tab"
                      href="#search"
                      onClick={() => {
                        this.props.setCampaignAux({
                          tabState: "search",
                        })
                      }}
                    >
                      Search
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class={`nav-link ${
                        modcampaign.tabState === "edit" ? "active" : ""
                      }`}
                      data-toggle="tab"
                      href="#edit"
                      onClick={() => {
                        this.props.setCampaignAux({
                          tabState: "edit",
                        })
                      }}
                    >
                      Manage
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class={`nav-link ${
                        modcampaign.tabState === "link" ? "active" : ""
                      }`}
                      data-toggle="tab"
                      href="#link"
                      onClick={() => {
                        this.props.setCampaignAux({
                          tabState: "link",
                        })
                      }}
                    >
                      Link
                    </a>
                  </li>
                </ul>
                <div
                  class="tab-content p-2 bg-white-1 rounded-corners-bottom"
                  id="tabContent"
                >
                  <div
                    class="tab-pane fade show active"
                    id="search"
                    role="tabpanel"
                    aria-labelledby="search-tab"
                  >
                    <div class="d-flex flex-row">
                      <select
                        class="form-control form-control-sm"
                        id="campaignSearch"
                        name="campaignSearch"
                        onChange={this.handleSearch}
                      >
                        <option>Select...</option>

                        {modcampaignlist
                          ? modcampaignlist.map((item, i) => (
                              <option
                                key={i}
                                value={item.id.replace("ac-", "")}
                              >
                                {item.name} - {item.id.replace("ac-", "")}
                              </option>
                            ))
                          : null}
                      </select>
                      <button
                        class="btn btn-link"
                        onClick={() => {
                          this.props.modifyCampaign("list", null, null, null)
                        }}
                      >
                        <MdRefresh />
                      </button>
                    </div>
                  </div>

                  <div
                    class="tab-pane fade"
                    id="edit"
                    role="tabpanel"
                    aria-labelledby="edit-tab"
                  >
                    <div class="form-group d-flex flex-row">
                      <select
                        class={`form-control form-control-sm ${
                          modcampaignerror && modcampaignerror.mode
                            ? "border-2-red-1"
                            : ""
                        }`}
                        id="campaignMode"
                        name="campaignMode"
                        onChange={this.handleUpdate}
                        value={modcampaign.campaignMode}
                      >
                        <option value="add">+ Add new</option>
                        {modcampaignlist
                          ? modcampaignlist.map((item, i) => (
                              <option
                                key={i}
                                value={item.id.replace("ac-", "")}
                              >
                                {item.name} - {item.id.replace("ac-", "")}
                              </option>
                            ))
                          : null}
                      </select>
                      <button
                        class="btn btn-link"
                        onClick={() => {
                          this.props.modifyCampaign("list", null, null, null)
                        }}
                      >
                        <MdRefresh />
                      </button>
                    </div>
                    <div class="form-group">
                      <input
                        type="email"
                        class={`form-control form-control-sm ${
                          modcampaignerror && modcampaignerror.name
                            ? "border-2-red-1"
                            : ""
                        }`}
                        id="campaignName"
                        name="campaignName"
                        placeholder="Campaign Name"
                        value={modcampaign.campaignName}
                        onChange={this.handleUpdate}
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="text"
                        class={`form-control form-control-sm ${
                          modcampaignerror && modcampaignerror.id
                            ? "border-2-red-1"
                            : ""
                        }`}
                        id="campaignId"
                        name="campaignId"
                        placeholder="Campaign ID"
                        value={modcampaign.campaignId}
                        onChange={this.handleUpdate}
                      />
                    </div>
                    <div class="d-flex flex-row w-100">
                      {this.props.modcampaign.campaignMode === "add" ? (
                        <button
                          class="btn btn-sm btn-primary btn-cta"
                          onClick={() =>
                            this.props.modifyCampaign(
                              modcampaign.campaignMode,
                              modcampaign.campaignId,
                              modcampaign.campaignName,
                              userdata.attributes.email
                            )
                          }
                        >
                          Add campaign
                        </button>
                      ) : null}

                      {this.props.modcampaign.campaignMode !== "add" ? (
                        <button
                          class="btn btn-sm btn-danger rounded-corners ml-auto"
                          onClick={() =>
                            this.props.modifyCampaign(
                              "delete",
                              modcampaign.campaignId,
                              modcampaign.campaignName,
                              null
                            )
                          }
                        >
                          <FaTrashAlt />
                        </button>
                      ) : null}
                    </div>
                  </div>

                  <div
                    class="tab-pane fade "
                    id="link"
                    role="tabpanel"
                    aria-labelledby="link-tab"
                  >
                    <div class="d-flex flex-column">
                      {modcampaign && modcampaign.linkRewrite ? (
                        <div class="p-2 mb-4 d-flex flex-row">
                          <small>{modcampaign.linkRewrite}</small>
                          <div class="d-flex flex-column ml-4">
                            <button
                              class="btn btn-link"
                              onClick={() =>
                                this.copyText(modcampaign.linkRewrite)
                              }
                            >
                              <MdContentCopy />
                            </button>
                            {this.state.copied ? (
                              <div>
                                <small>Copied</small>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <input
                        type="text"
                        class="form-control form-control-sm my-2"
                        id="campaignUrl"
                        name="campaignUrl"
                        aria-describedby="campaignUrl"
                        placeholder="URL"
                        value={modcampaign.campaignUrl}
                        onChange={this.handleUpdate}
                      />

                      <div class="form-group d-flex flex-row my-2">
                        <select
                          class="form-control form-control-sm"
                          id="campaignSearchLink"
                          name="campaignSearchLink"
                          onChange={this.handleSearch}
                        >
                          <option>Campaign...</option>

                          {modcampaignlist
                            ? modcampaignlist.map((item, i) => (
                                <option
                                  key={i}
                                  value={item.id.replace("ac-", "")}
                                >
                                  {item.name} - {item.id.replace("ac-", "")}
                                </option>
                              ))
                            : null}
                        </select>
                        <button
                          class="btn btn-link"
                          onClick={() => {
                            this.props.modifyCampaign("list", null, null, null)
                          }}
                        >
                          <MdRefresh />
                        </button>
                      </div>
                      <input
                        type="text"
                        class="form-control form-control-sm my-2"
                        id="crmId"
                        name="crmId"
                        aria-describedby="crmId"
                        placeholder="CRM ID (optional)"
                        value={modcampaign.crmId}
                        onChange={this.handleUpdate}
                      />
                      <button
                        class="btn btn-sm btn-primary ml-2 mt-4"
                        onClick={() => {
                          this.handleLinkRewrite(
                            modcampaign.campaignUrl,
                            modcampaign.crmId,
                            modcampaign.campaignSearchLink
                          )
                        }}
                      >
                        Create
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 form-group d-flex align-items-end justify-content-end"></div>
            </div>
            <div
              class={`row p-2 ${
                modcampaign.tabState === "search" ? "d-block" : "d-none"
              }`}
            >
              <div class="col-md-12">
                {modcampaignengage
                  ? modcampaignengage.map((item, i) => (
                      <div
                        key={i}
                        class={`row p-1 py-2 d-flex flex-row ${
                          i / 2 !== 0 ? "bg-gray-7" : ""
                        }`}
                      >
                        <div class="col-md-1">
                          <a
                            target="_blank"
                            href={`https://app.hubspot.com/contacts/6297259/contact/${item.sub}/`}
                          >
                            {item.sub}
                          </a>
                        </div>
                        <div class="col-md-6">
                          <a
                            target="_blank"
                            href={`https://storylava.com${item.path}`}
                          >
                            {item.path}
                          </a>
                        </div>
                        <div class="col-md-5">
                          {moment
                            .unix(item.datecreated)
                            .format("MM/DD/YYYY, h:mm:ss a")}
                        </div>
                      </div>
                    ))
                  : null}
                {modcampaignengage && modcampaignengage.length === 0 ? (
                  <div>
                    <h4 class="mt-2">
                      <small>Nada</small>
                    </h4>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3" style={{ top: -5 }}>
          <div class="border-1-gray-6 rounded-corners p-3 status-bar">
            {isLoading ? (
              <div class="pb-2">
                <Loading class="small" />
              </div>
            ) : null}
            {modcampaignhistory
              ? modcampaignhistory.map((item, i) => (
                  <div key={i}>
                    <EmailStatus
                      marker={modcampaignhistory.length - i - 1}
                      message={item.message}
                      mode={item.mode}
                      data={item.data}
                    />
                  </div>
                ))
              : null}
          </div>
        </div>
      </>
    )
  }
}

// Snippet.propTypes = {
//   // result: PropTypes.array,
//   // increment: PropTypes.func.isRequired,
//   clear: PropTypes.func.isRequired,
// }

// export default Snippet
export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
