// import Amplify, { API, Auth, Cache } from "aws-amplify"

import Amplify from "@aws-amplify/core"
import API from "@aws-amplify/api"
import Auth from "@aws-amplify/auth"
import Cache from "@aws-amplify/cache"
import { navigate } from "@reach/router"

import moment from "moment"

export function compositeImage(baseimage,overlayimage) {
  let cid = Cache.getItem("cid")

  console.log("cid&&&&&")
  console.log(cid)

  let apiName = "Storylavaimg"

  let path = "image/promo"

  return dispatch => {
    dispatch({
      type: "LOADING_START",
    })
    Auth.currentAuthenticatedUser()
      .then(user => {
        //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
        console.log("get campaigns function---")
        console.log("[[[user]]]")
        console.log(user)
        let jwtToken = user.signInUserSession.idToken.jwtToken

        let options = {
          headers: {
            Authorization: jwtToken,
          },
          body: {
            baseimage,
            overlayimage
          },
        }

        console.log("[[[[[options]]]]]")
        console.log(options)

        //begin promise
        new Promise(resolve => {
          API.post(apiName, path, options)
            .then(response => {
              const data = response || {}

              console.log("((data - compositeImage))")
              console.log(data)

              let result = data

              console.log("--compositeImage--")
              console.log(result)

              dispatch({
                type: "LOADING_END",
              })
              return resolve(
                dispatch({
                  type: "IMAGECOMPOSITE_REPLACE",
                  data: result,
                })
              )
            })
            .catch(error => {
              console.log(error)
            })
        }) //end promise
      })
      .catch(error => {
        console.log(error)
      }) //end get Auth token
  }
}
